import { BlockList } from '@zillow/cms-blocks';
import { Box, Heading } from '@zillow/constellation';
import Script from 'next/script';
import React from 'react';

import { NEXT_PUBLIC_KNOTCH_PA_POST_ACCOUNT_ID } from '../../../../server/config';
import FooterTemplate from '../../FooterTemplate/FooterTemplate';
import { SidebarWrapper } from '../../MasterLayout';

import PostByLine from './PostByLine';
import PostCategories from './PostCategories';
import PostFeaturedImage from './PostFeaturedImage';

type Block = {
    blockName: string;
    attrs: Record<string, unknown>;
    innerBlocks?: Block[];
};

export type Media = {
    sourceUrl: string;
    altText?: string;
    caption?: {
        raw: string;
    };
    mediaDetails: {
        sizes: {
            large?: {
                sourceUrl: string;
                width: number;
                height: number;
            };
            medium?: {
                sourceUrl: string;
                width: number;
                height: number;
            };
            thumbnail?: {
                sourceUrl: string;
                width: number;
                height: number;
            };
        };
        width: number;
        height: number;
    };
};

export type Author = {
    name: string;
    avatar: string;
};

export type Term = {
    id: number;
    name: string;
    link: string;
    slug: string;
    parent: number;
};

export type Post = {
    title: {
        raw: string;
    };
    slug: string;
    sidebar?: {
        blocks: string;
    };
    footer?: {
        blocks: string;
    };
    blocks: Block[];
    date: string;
    readTime?: number;
    primaryCategory?: Term;
    categories?: Term[];
    author: Author;
    featuredImage?: Media;
};

type PostTemplateProps = {
    post: Post;
};

const PostTemplate = ({
    post: {
        title: { raw: title },
        sidebar,
        footer,
        categories,
        primaryCategory,
        author,
        readTime,
        date,
        featuredImage,
    },
    post,
}: PostTemplateProps) => {
    return (
        <>
            <Box
                sx={{
                    maxWidth: '$breakpoints.xl',
                    margin: '$spacing.lg auto',
                    padding: '0 $spacing.sm',
                }}
            >
                <Box
                    sx={{
                        '@media $lg': {
                            display: 'flex',
                        },
                    }}
                >
                    <Box
                        sx={{
                            flex: '1 1 0',
                            minWidth: 0,
                        }}
                    >
                        <PostCategories
                            categories={categories}
                            primaryCategory={primaryCategory}
                        />

                        <Heading
                            level={1}
                            fontType={{
                                default: 'h4',
                                lg: 'h3',
                            }}
                            fontFamily="serif"
                            marginBottom="sm"
                        >
                            {title}
                        </Heading>

                        {featuredImage && (
                            <PostFeaturedImage
                                featuredImage={featuredImage}
                                defaultAlt={title}
                            />
                        )}

                        <PostByLine
                            author={author}
                            readTime={readTime}
                            date={date}
                        />

                        <Box sx={{ marginBottom: '$spacing.lg' }}>
                            <BlockList blocks={post.blocks || []} />
                        </Box>

                        <div className="knotch_placeholder" />
                        <Script
                            src="https://www.knotch-cdn.com/unit/latest/knotch.min.js"
                            data-account={NEXT_PUBLIC_KNOTCH_PA_POST_ACCOUNT_ID}
                        />
                    </Box>

                    {sidebar && (
                        <Box
                            sx={{
                                marginTop: '$spacing.xl',
                                marginLeft: 'auto',
                                marginRight: 'auto',
                                width: '300px',
                                '@media $md_lte': {
                                    width: '100%',
                                    marginTop: 0,
                                },
                                '@media $lg': {
                                    marginLeft: '$spacing.xl',
                                    marginRight: 0,
                                    marginTop: 0,
                                },
                            }}
                        >
                            <SidebarWrapper sticky top="101px">
                                <BlockList
                                    blocks={JSON.parse(sidebar?.blocks || '[]')}
                                />
                            </SidebarWrapper>
                        </Box>
                    )}
                </Box>
            </Box>
            {footer && <FooterTemplate footer={footer} />}
        </>
    );
};

export default PostTemplate;
