import { Anchor, Box, Tag, TAG_APPEARANCES } from '@zillow/constellation';
import { uniqBy } from 'lodash';
import React from 'react';

import { wrap } from '../../../util/wrap';

import { Term } from './PostTemplate';

const isGray = (color: string) =>
    ['gray', 'closeable', 'transparent'].includes(color);
const TAG_APPEARANCES_ARRAY = Object.values(TAG_APPEARANCES).filter(
    (color) => !isGray(color as string)
);

const PostCategories = ({
    categories,
    primaryCategory,
}: {
    categories?: Term[];
    primaryCategory?: Term;
}) => {
    if (!categories?.length) {
        return null;
    }

    const uniqueCategories = uniqBy(
        [primaryCategory, ...categories],
        'id'
    ).filter(Boolean);

    return (
        <Box
            sx={{
                marginBottom: '$spacing.sm',
                display: 'flex',
                flexWrap: 'wrap',
                alignItems: 'center',
                gap: '$spacing.sm',
            }}
        >
            {uniqueCategories.map((category, index) => (
                <Box
                    as={Anchor}
                    sx={{
                        textDecoration: 'none',
                    }}
                    key={category?.id}
                    href={category.link}
                >
                    <Tag
                        textTransform="none"
                        // It's ok to use index here as this is not going to change dynamically.
                        key={index}
                        appearance={
                            TAG_APPEARANCES_ARRAY[
                                wrap(0, TAG_APPEARANCES_ARRAY.length - 1, index)
                            ]
                        }
                    >
                        <span
                            dangerouslySetInnerHTML={{
                                __html: category.name,
                            }}
                        />
                    </Tag>
                </Box>
            ))}
        </Box>
    );
};

export default PostCategories;
