import {
    BlockList,
    PlacementProvider,
    getBlocksData,
    getPost,
    initializeApollo,
} from '@zillow/cms-blocks';
import React from 'react';

import { REVALIDATION_INTERVAL } from '../../../server/config';
import FooterTemplate from '../../components/FooterTemplate/FooterTemplate';
import PostTemplate from '../../components/PA/PostTemplate/PostTemplate';
import ZPALayout from '../../components/ZPALayout/ZPALayout';

export type ContentProps = Awaited<
    ReturnType<typeof getStaticProps>
>['props'] & {
    preview?: boolean;
};

const PageTemplate = ({
    post: { blocks, footer },
}: ContentProps): React.ReactNode => {
    return (
        <>
            <BlockList blocks={blocks || []} />
            {footer && <FooterTemplate footer={footer} />}
        </>
    );
};

function Content({
    post: { head, type, menu, header },
    post,
    preview,
}: ContentProps): React.ReactNode {
    if (type !== 'post' && type !== 'page') {
        throw new Error(`Unexpected content type ${type}`);
    }

    return (
        <ZPALayout head={head} preview={preview} submenu={menu} header={header}>
            <PlacementProvider placement={type}>
                {type === 'post' && <PostTemplate post={post} />}
                {type === 'page' && <PageTemplate post={post} />}
            </PlacementProvider>
        </ZPALayout>
    );
}

export async function getStaticProps({ params }) {
    const { slug = ['/'] } = params;

    initializeApollo();

    const { post, settings } = await getPost({
        variables: {
            site: 'ZPA',
            id: slug.join('/'),
            idType: 'URI',
            withSettings: true,
        },
        fetchPolicy: 'no-cache',
    });

    if (post?.status !== 'publish') {
        return {
            notFound: true,
        };
    }

    const blocks = await getBlocksData(JSON.parse(post?.blocks || '[]'));
    const menu = post?.type === 'post' ? settings?.menu : post?.menu;

    return {
        props: {
            post: {
                ...post,
                blocks,
                menu: menu || null,
            },
        },
        revalidate: REVALIDATION_INTERVAL,
    };
}

export async function getStaticPaths() {
    return {
        paths: [],
        fallback: 'blocking',
    };
}

export default Content;
