import {
    Avatar,
    Box,
    IconClockOutline,
    IconUser,
    Image,
    MediaObject,
    Paragraph,
} from '@zillow/constellation';
import { withPreload } from '@zillow/zrs-cms';
import React from 'react';

import { Post } from './PostTemplate';

const ImagePreload = withPreload(Image);

const PostByLine = ({
    readTime,
    author,
    date,
}: {
    readTime?: Post['readTime'];
    author?: Post['author'];
    date: Post['date'];
}) => {
    const publishDateObject = new Date(date);
    const AvatarImage = author?.avatar ? (
        <ImagePreload src={author.avatar} />
    ) : (
        <IconUser />
    );

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: '$spacing.md',
            }}
        >
            <MediaObject
                media={<Avatar size="md">{AvatarImage}</Avatar>}
                align="center"
            >
                {!!author?.name && (
                    <Paragraph fontType="bodySmall">{author?.name}</Paragraph>
                )}
                <Paragraph fontType="bodySmall">
                    {publishDateObject.toLocaleDateString('en-US', {
                        month: 'long',
                        day: 'numeric',
                        year: 'numeric',
                    })}
                </Paragraph>
            </MediaObject>
            <MediaObject
                media={<IconClockOutline size="sm" fontColor="textMedium" />}
                align="center"
                gutter="xs"
            >
                <Paragraph fontType="bodySmall" fontColor="textMedium">
                    {readTime || 5} Minute Read
                </Paragraph>
            </MediaObject>
        </Box>
    );
};

export default PostByLine;
