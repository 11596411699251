import { Box, Image } from '@zillow/constellation';
import { Source, withPreload } from '@zillow/zrs-cms';
import React from 'react';

import { Post } from './PostTemplate';

const ImagePreload = withPreload(Image);
const SourcePreload = withPreload(Source);

const PostFeaturedImage = ({
    featuredImage,
    defaultAlt,
}: {
    featuredImage: Post['featuredImage'];
    defaultAlt: string;
}) => {
    if (!featuredImage) {
        return null;
    }

    const mediumImage = featuredImage?.mediaDetails?.sizes?.medium;
    const largeImage = featuredImage?.mediaDetails?.sizes?.large;
    // Sometimes mediumImage and largeImage are null, but fullImage is
    // available. In this case, fullImage is used.
    const fullImage = featuredImage
        ? {
              sourceUrl: featuredImage.sourceUrl,
              width: featuredImage.mediaDetails.width,
              height: featuredImage.mediaDetails.height,
          }
        : null;

    if (!mediumImage && !largeImage && !fullImage) {
        return null;
    }

    return (
        <Box sx={{ textAlign: 'center', marginBottom: '$spacing.sm' }}>
            <Box
                as="picture"
                sx={{
                    'img, source': {
                        borderRadius: '$radii.xxl',
                        overflow: 'hidden',
                        marginBottom: '$spacing.xs',
                        aspectRatio: '16 / 9',
                        objectFit: 'cover',
                        objectPosition: 'center',
                        height: 'auto',
                        width: '100%',
                    },
                }}
            >
                {!!mediumImage && (
                    <SourcePreload
                        srcSet={mediumImage?.sourceUrl}
                        media="md_lte"
                        width={mediumImage.width}
                        height={mediumImage.height}
                    />
                )}
                {!!largeImage && (
                    <ImagePreload
                        src={largeImage?.sourceUrl || mediumImage?.sourceUrl}
                        alt={
                            featuredImage.altText ||
                            featuredImage.caption?.raw ||
                            defaultAlt
                        }
                        width={largeImage.width}
                        height={largeImage.height}
                    />
                )}
                {!largeImage && !!fullImage && (
                    <ImagePreload
                        src={fullImage.sourceUrl}
                        width={fullImage.width}
                        height={fullImage.height}
                        alt={
                            featuredImage.altText ||
                            featuredImage.caption?.raw ||
                            defaultAlt
                        }
                    />
                )}
            </Box>
        </Box>
    );
};

export default PostFeaturedImage;
