/**
 * Wraps a number when it breaches the defined range.
 *
 * ```
 * test("wrap", () => {
 *     expect(wrap(-100, 100, -100)).toBe(-100)
 *     expect(wrap(-100, 100, 0)).toBe(0)
 *     expect(wrap(-100, 100, -200)).toBe(0)
 *     expect(wrap(-100, 100, 101)).toBe(-99)
 * })
 * ```
 */

export function wrap(min: number, max: number, value: number): number {
    const rangeSize = max - min;
    return ((((value - min) % rangeSize) + rangeSize) % rangeSize) + min;
}
